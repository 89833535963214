@import url('https://fonts.googleapis.com/css?family=Lora:400,400i,700,700i');


@font-face {
  font-family: Impact;
  src: url('./fonts/impact.ttf');
}

@font-face {
  font-family: Arial;
  src: url('./fonts/arial.ttf');
}

@font-face {
  font-family: Comic;
  src: url('./fonts/comicsans.ttf');
}

@font-face {
  font-family: Helvetica;
  src: url('./fonts/helvetica.ttf');
}

@font-face {
  font-family: Montserrat;
  src: url('./fonts/montserrat.ttf');
}

@font-face {
  font-family: Myriadpro;
  src: url('./fonts/myriadpro.ttf');
}

html, body {
  height: 100%;
  font-family: 'Lora', serif;
  background: #fff;
  color: #000000;
}

.btn-primary {
  background: #665 !important;
  border: none !important;
  outline: none !important;
  width: 100%;
}

.form-control:focus {
  border-color: #665;
}

.btn-primary:hover, .btn-primary:active, .btn-primary:focus {
  background: #393939 !important;
  outline: #000 !important;
  border: none !important;
}



.meme-gen-modal {
  
  max-width: fit-content;
  max-height: fit-content;
  
}

.modal-body {
  padding-right: 2px;
  display: flex;
  border-radius: 7px;
  border: 2px inset #545454;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 1);
  background: radial-gradient(
    circle,
    #80c18c 60%,
    silver 100%
  );
  
}

.modal-body1 {
  
  display: flex;
  border-radius: 7px;
  border: 2px inset #545454;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 1);
  background: radial-gradient(
    circle,
    #80c18c 60%,
    silver 100%
  );
  
}

/* .modal-header {
 
  
  background-color: #7a967f;
  font-family: Impact;
  color: #ffffff;
  -webkit-text-stroke: .5px #000;
	text-shadow: -1px 1px 1px #000,
				  1px 1px 1px #000,
				  1px -1px 0 #000,
				  -1px -1px 0 #000;
  font-size: 1rem;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
} */

.meme-form {
  max-height: 53vh;
  margin-left: 20px;
  padding-left: 20px;
  width: 300px;
  border-left: 2px inset #7a967f;
  overflow-y: scroll;
  padding-right: 5px;
}

.form-control:focus {
  box-shadow: none;
}

.image-holder {
  
  width: 295px;
  height: 295px;
  margin: 10px 10px;
  position: relative;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
  
}

.image-holder:hover {
  box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.8), 0 15px 20px 0 rgba(0, 0, 0, 0.8);
}

.row {
  margin: 0 auto;
  justify-content: center;
}




.main-content2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
  width: 800px;
  
}

.main-content2 h1 {
  font-size: 1rem;
  
}

.main-content1 {
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 20px;
  
}

.main-content1 h1 {
  margin-bottom: 20px;
  font-size: 3rem;
  font-weight: 800;
  color: #ffffff;
  -webkit-text-stroke: 1px #000;
	text-shadow: -1px 1px 2px #000,
				  1px 1px 2px #000,
				  1px -1px 0 #000,
				  -1px -1px 0 #000;
}

.main-content1 p {
  margin-left:auto;
  margin-right:auto;
  width:65%;
  
  width: 400;
  font-size: 1rem;
 
  color: #000000;
  
}

.main-content {
  display: flex;
}

.main-content h1 {
  font-size: 3rem;
  font-weight: 800;
  color: #ffffff;
  -webkit-text-stroke: 1px #000;
	text-shadow: -1px 1px 2px #000,
				  1px 1px 2px #000,
				  1px -1px 0 #000,
				  -1px -1px 0 #000;
}

.sidebar {
  min-width: 300px;
  background: #333333;
  min-height: 100vh;
  margin-right: 7.5px;
  padding: 20px;
  max-width: 300px;
}

.sidebar p {
  margin-bottom: 30px;
  font-size: 15.12px;
  display: inline-block;
  color: #fff;
  letter-spacing: 1px;
  font-weight: 300;
  text-align: center;
}

.content {
  margin-left:auto;
  margin-right:auto;
  width:85%;

  display: flex;
 
  margin-top: 10px;
}



.meme-bottom-caption {
  position: absolute;
  font-size: 20px;
  display: block;
  width: 100%;
  font-family: Impact;
  text-align: center;
  text-transform: uppercase;
  bottom: 0;
  padding: 5px;
  color: #fff;
  letter-spacing: 1px;
  background: linear-gradient(to left, rgb(51, 51, 51), rgba(51, 51, 51, 0.81));
  
}

.meme-top-caption {
  position: absolute;
  font-size: 20px;
  display: block;
  width: 100%;
  font-family: Impact;
  text-align: center;
  text-transform: uppercase;
  top: 0;
  padding: 5px;
  color: #fff;
  letter-spacing: 1px;
  background: linear-gradient(to left, rgb(51, 51, 51), rgba(51, 51, 51, 0.81));
}

.modal {
  
  top: 130px;
  right: 100px;
  /* Hide the scrollbar itself */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Microsoft Edge */

  /* Style the scrollbar track to match the background color */
  ::-webkit-scrollbar {
    width: 0; /* For Chrome, Safari, and Opera */
  }

  /* Hide the scrollbar thumb by making it match the background color */
  ::-webkit-scrollbar-thumb {
    background-color: transparent; /* Change this to match your background color */
  }
  
}

.scrollable-modal {
  max-height: 60vh;
  overflow-y: scroll;

  /* Hide the scrollbar itself */
  scrollbar-width: thin; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Microsoft Edge */

  /* Style the scrollbar track to match the background color */
  ::-webkit-scrollbar {
    width: 6px; /* For Chrome, Safari, and Opera */
  }

  /* Style the scrollbar thumb (handle) */
  ::-webkit-scrollbar-thumb {
    background-color: #7a967f; /* Color of the thumb */
    border-radius: 4px; /* Rounded corners for the thumb */
    border: 1px solid #e0e0e0; /* Border around the thumb */
  }
}
.scrollable-modal:hover {
  scrollbar-width: thin; /* Show scrollbar in Firefox */
}

@media (max-width: 844px) {
  .scrollable-modal {
    max-height: 60vh;
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer and Microsoft Edge */
    ::-webkit-scrollbar {
      width: 0; /* For Chrome, Safari, and Opera */
    }
    .meme-form {
      max-height: 128vh;
      margin-left: 20px;
      padding-left: 20px;
      width: 200px;
      border-left: 2px inset #7a967f;
      overflow-y: scroll;
      
    }
    ::-webkit-scrollbar-thumb {
      background-color: transparent; /* Change this to match your background color */
    }
  }
  .image-holder {
  
    width: 100px;
    height: 100px;
    margin: 10px 10px;
    position: relative;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
    
  }
  .meme-bottom-caption {
    position: absolute;
    font-size: 5px;
    display: block;
    width: 100%;
    font-family: Impact;
    text-align: center;
    text-transform: uppercase;
    bottom: 0;
    padding: 5px;
    color: #fff;
    letter-spacing: 1px;
    background: linear-gradient(to left, rgb(51, 51, 51), rgba(51, 51, 51, 0.81));
    
  }
  
  .meme-top-caption {
    position: absolute;
    font-size: 5px;
    display: block;
    width: 100%;
    font-family: Impact;
    text-align: center;
    text-transform: uppercase;
    top: 0;
    padding: 5px;
    color: #fff;
    letter-spacing: 1px;
    background: linear-gradient(to left, rgb(51, 51, 51), rgba(51, 51, 51, 0.81));
  }
  
}

.dual-content {
  display: flex;
}

.dual-content .content-left {
  flex: 1;
  padding: 10px;
}

.dual-content .content-right {
  flex: 1;
  padding: 10px;
}