.about-container {
  margin-top: 4rem;
  margin-bottom: 4rem;
  color: #2a2a2a;
  text-align: center;
}

.about-container h1 {
  font-size: 3rem;
  padding-bottom: 1.5rem;
  font-weight: 800;
  color: #ffffff;
  -webkit-text-stroke: 1px #000;
	text-shadow: -1px 1px 2px #000,
				  1px 1px 2px #000,
				  1px -1px 0 #000,
				  -1px -1px 0 #000;
}

.about-container h2 {
  font-size: 1.5rem;
  padding-bottom: 1rem;
  font-weight: 800;
  color: #ffffff;
  -webkit-text-stroke: 1px #000;
	text-shadow: -1px 1px 2px #000,
				  1px 1px 2px #000,
				  1px -1px 0 #000,
				  -1px -1px 0 #000;
}

.about-container h3 {
  font-size: 1.5rem;
  padding-bottom: 1rem;
  font-weight: 800;
  color: #ffffff;
  -webkit-text-stroke: 1px #000;
	text-shadow: -1px 1px 2px #000,
				  1px 1px 2px #000,
				  1px -1px 0 #000,
				  -1px -1px 0 #000;
}

.about-container p {
  padding-bottom: 2rem;
}

@media screen and (max-width: 850px) {
  .about-container {
    margin: 4rem 2rem;
  }
}
