.footer {
  background: #000;
  color: #fff;
  padding: 4rem 6rem;
}

.top {
  display: flex;
  align-items: center;
  text-align: start;
  justify-content: center;
  
}

.top h1 {
  font-size: 3rem;
  font-weight: 800;
  color: #000000;
  -webkit-text-stroke: 1px #ffffff;
	text-shadow: -1px 1px 2px #ffffff,
				  1px 1px 2px #ffffff,
				  1px -1px 0 #ffffff,
				  -1px -1px 0 #ffffff;
}

.top i {
  color: #fff;
  font-size: 2rem;
  margin-left: 1rem;
}

.top i:hover {
  color: #01959a;
}

.bottom {
  padding-top: 2rem;
  text-align: start;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.bottom p{
  padding-top: 4rem;
}

.bottom-icon {
  position: absolute;

}

.bottom i {
  color: #fff;
  font-size: 2rem;
  margin-left: 1rem;
}

.bottom i:hover {
  color: #80c18c;
}


.bottom h4 {
  font-size: 1.3rem;
  padding: 1rem 0 0.8rem 0;
}

.bottom a {
  text-decoration: none;
  color: #fafafa;
  padding-bottom: 0.4rem;
  font-size: 1.1rem;
}

@media screen and (max-width: 850px) {
  .footer {
    padding: 4rem 2rem;
  }

  .footer i {
    margin: 1rem 1rem 0 0;
  }

  .bottom div {
    width: 50%;
  }
}
