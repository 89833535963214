.trip {
  margin-top: 4rem;
  margin-bottom: 4rem;
  color: #000000;
}

.trip h1 {
  font-size: 3rem;
  font-weight: 800;
  color: #ffffff;
  -webkit-text-stroke: 1px #000;
	text-shadow: -1px 1px 2px #000,
				  1px 1px 2px #000,
				  1px -1px 0 #000,
				  -1px -1px 0 #000;
}

ul { text-align:center; list-style-position:inside; }

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.tripcard {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
}

.t-card {
  background: #7a967f;
  width: 32%;
  text-align: start;
  border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: rgb(0, 0, 0);
  border-radius: 10px;
  cursor: pointer;
  padding: 1rem 0.8rem;
  margin: 0.05rem;
  box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.8), 0 15px 20px 0 rgba(0, 0, 0, 0.8);
  text-align: center;
}
.t-image {
  height: 200px;
  overflow: hidden;
  border-radius: 7px;
}

.t-image img {
  width: 100%;
  height: 100%;
  border-radius: 7px;
  transition: 0.3s ease-in-out;
}

.t-card:hover img {
  transform: scale(1.3);
  overflow: hidden;
}

.t-card h4 {
  font-size: 1.3rem;
  padding: 0.9rem 0 0.2rem 0;
  font-weight: 800;
  color: #ffffff;
  -webkit-text-stroke: 1px #000;
	text-shadow: -1px 1px 2px #000,
				  1px 1px 2px #000,
				  1px -1px 0 #000,
				  -1px -1px 0 #000;
}

@media screen and (max-width: 850px) {
  .trip {
    margin: 4rem 2rem;
  }
  .tripcard {
    
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
  }
  .t-card {
    
    margin-bottom: 1.5rem;
    width: 100%;
  }
}
