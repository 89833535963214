.destination {
  margin-top: 4rem;
  margin-bottom: 4rem;
  color: #2a2a2a;
}

.destination h1 {
  font-size: 3rem;
  font-weight: 800;
  color: #ffffff;
  -webkit-text-stroke: 1px #000;
	text-shadow: -1px 1px 2px #000,
				  1px 1px 2px #000,
				  1px -1px 0 #000,
				  -1px -1px 0 #000;
}

.first-des {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4.5rem;
}

.first-des-reverse {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  margin-top: 6.5rem;
}

.des-text {
  width: 45%;
  text-align: start;
  font-size: 1.1rem;
}

.des-text h2 {
  
 
  font-weight: 800;
  color: #ffffff;
  -webkit-text-stroke: 1px #000;
	text-shadow: -1px 1px 2px #000,
				  1px 1px 2px #000,
				  1px -1px 0 #000,
				  -1px -1px 0 #000;
}

.des-text h3 {
  padding-bottom: 0.7rem;
  
  font-weight: 800;
  color: #ffffff;
  -webkit-text-stroke: 1px #000;
	text-shadow: -1px 1px 2px #000,
				  1px 1px 2px #000,
				  1px -1px 0 #000,
				  -1px -1px 0 #000;
}

.image {
  position: relative;
  padding-top: 64px;
  padding-bottom: 35px;
  width: 50%;
  display: flex;
  justify-content: space-between;
  z-index: -99;
}

.image img {
  width: 90%;
  height: 500px;
  object-fit: cover;
  border-radius: 6px;
  box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.8), 0 15px 20px 0 rgba(0, 0, 0, 0.8);
  border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: rgb(0, 0, 0);
  border-radius: 10px;
}

.image img:nth-child(2) {
  position: absolute;
  top: -10%;
  right: 0;
}

@media screen and (max-width: 414px) {
  .destination {
    margin: 4rem 2rem;
  }
  .first-des {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 2.5rem;
  }

  
  .first-des-reverse {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 2.5rem;
  }

  .des-text {
    width: 100%;
  }

  .image {
    padding-top: 80px;
    padding-bottom: 80px;
    width: 100%;
    margin: 1.8rem 0;
  }

  .image img {
    height: 100%;
    width: 100%;
  }
}


@media screen and (min-width: 1921px) {
  



  .image {
    position: relative;
    padding-top: 200px;
    padding-bottom: 170px;
    width: 50%;
    display: flex;
    justify-content: space-between;
    z-index: -99;
  }
  
  .image img {
    width: 90%;
    height: 90%;
    object-fit: cover;
    border-radius: 6px;
    box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.8), 0 15px 20px 0 rgba(0, 0, 0, 0.8);
    border-style: solid;
      border-width: 2px 2px 2px 2px;
      border-color: rgb(0, 0, 0);
    border-radius: 10px;
  }
}