.hero {
  width: 100%;
  height: 100vh;
  position: relative;
  
}
.hero-mid {
  width: 100%;
  height: 70vh;
  position: relative;
}

.hero-mid h1 {
  padding-top: 4rem !important;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  
}

.hero-text {
  position: absolute;
  
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  
}

.hero-text h1 {
  font-size: 3rem;
  font-weight: 800;
  color: #ffffff;
  -webkit-text-stroke: 1px #000;
	text-shadow: -1px 1px 2px #000,
				  1px 1px 2px #000,
				  1px -1px 0 #000,
				  -1px -1px 0 #000;
}

.hero-text1 {
  position: absolute;
  justify-items: stretch;
  top: 45%;
  left: 50%;
  transform: translate(-50%, 200%);
  width: 100%;
}

.box {
  inline-size: 150px; 
}

.hero-text p {
  text-align: center;
  display: flex;
  justify-content: center;
  color: #f4f4f4;
  font-size: 1.3rem;
  padding: 0.5rem 0 2rem 0;
  
}

.hero-text .show {
  text-decoration: none;
  background: #fff;
  padding: 1rem 2rem;
  border-radius: 6px;
  font-size: 1.1rem;
  font-weight: bold;
  letter-spacing: 2px;
  color: #222;
}

.hero-text .hide {
  display: none;
}

@media screen and (max-width: 555px) {
  .hero-text h1 {
    padding: 10px 20px;
  }
  .hero-text p {
    font-size: 1.1rem;
    padding: 0rem 0 2rem 0;
  }
  .hero-text .show {
    padding: 0.6rem 1.1rem;
    border-radius: 3px;
    font-size: 1rem;
    font-weight: bold;
  }
}
